<template>
  <div class="mt-lg-16">
    <v-layout row wrap justify-center>
      <v-card elevation="1" class="rounded-0">
        <v-card-title class="blue py-lg-7"></v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-container class="my-lg-5">
              <v-row class="card-row mt-1">
                <v-col cols="12" lg="6" class="mx-lg-2">
                  <v-text-field hide-details outlined dense label="Room name" v-model="inputObj.groupId"></v-text-field>
                </v-col>
                <v-col cols="12" lg="6" class="mx-lg-2">
                  <v-text-field hide-details outlined dense label="User name" v-model="inputObj.userName"></v-text-field>
                </v-col>
                <v-col cols="12" lg="6" class="text-center mt-lg-2">
                  <v-btn @click="newRoom"> submit </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-layout>
  </div>
</template>

<script>
export default {
  data () {
    return {
      inputObj: {}
    }
  },
  methods: {
    newRoom () {
      this.inputObj.tenant = this.tenant
      this.inputObj.groupId = '60c1d0acc93405308c336171'
      this.$socket.emit('newStream', this.inputObj, (data) => {
        this.$router.push(`vc/stream/${ data }`)
      })
    }
  }
}
</script>

<style scoped>
  .card-row {
    display: flex;
    justify-content: center;
  }
</style>